import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import { convertToBgImage } from 'gbimage-bridge'
import backgroundImage from '/src/dummy-data/images/backgroundImage'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const BackgroundImage = loadable(() => import('gatsby-background-image'))
const SearchForm = loadable(() => import('../SearchForm'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const Snowfall = loadable(() => import('react-snowfall'))

const SearchHeader = ({ setSearchTerm, prevSearch, postTypeInfo, headingLevel, backgroundImage, addSnow }) => {
    const [options] = useContext(OptionsContext)
    const bgImageFile = backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
    const bgImage = convertToBgImage(bgImageFile)

    const HeadingTag = headingLevel

    return (
        <>
            <Section variant={'other'} size={'sm'} className={'c-search-header'}>
                {addSnow && <Snowfall />}

                <div className="c-search-header__inner">
                    <StaticImage
                        src={'../../../images/pl-logotype-white.png'}
                        width={40}
                        alt={`${options.siteData.title} logotype`}
                    />

                    {postTypeInfo.hero.eyebrow && (
                        <div className="c-search-header__eyebrow">{postTypeInfo.hero.eyebrow}</div>
                    )}

                    {postTypeInfo.hero.title && (
                        <HeadingTag className="c-search-header__title">{postTypeInfo.hero.title}</HeadingTag>
                    )}
                </div>

                <div className="c-search-header__node">
                    <div className="row">
                        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-xl-6 offset-xl-3">
                            <SearchForm prevSearch={prevSearch} setSearchTerm={setSearchTerm} uri={postTypeInfo.uri} />
                        </div>
                    </div>
                </div>

                {bgImage && (
                    <BackgroundImage
                        Tag={'span'}
                        className={'c-search-header__background'}
                        {...bgImage}
                        preserveStackingContext
                    ></BackgroundImage>
                )}
            </Section>
        </>
    )
}

SearchHeader.propTypes = {
    setSearchTerm: PropTypes.func,
    prevSearch: PropTypes.string,
    postTypeInfo: PropTypes.shape({
        postType: PropTypes.string,
        title: PropTypes.string,
        uri: PropTypes.string,
        hero: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string
        })
    }),
    headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']),
    backgroundImage: PropTypes.object,
    addSnow: PropTypes.bool
}

SearchHeader.defaultProps = {
    postTypeInfo: {
        postType: 'post',
        title: 'Post Type Title',
        uri: '/page-uri/',
        hero: {
            eyebrow: 'Hero eyebrow text goes here',
            title: 'Hero title text goes here'
        }
    },
    headingLevel: 'h1',
    backgroundImage: backgroundImage,
    addSnow: false
}

export default SearchHeader
